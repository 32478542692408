import React, {useCallback, useState} from "react"
import axios from 'axios';
import { useForm } from 'react-hook-form';

// 画像ファイル
import formOk from "@images/form-icon-ok.svg"
import formNg from "@images/form-icon-ng.svg"

export default function FormsParts(){

  // メールアドレス 設定
  const category_4 = 'email';
  const category_4_title = 'メールアドレス';
  const category_4_pholder = 'example@example.com';

  // パスワード 設定
  const category_5 = 'passward';
  const category_5_title = 'パスワード';
  const category_5_pholder = 'パスワードを決めてください（半角英数字）';

  // リファラルコード
  const category_6 = 'referralcode';
     
  // その他 設定
  const required = '[ 必須 ]';
  const submit = '送信';
  
  // 送信ボタンのステータス切り替え処理
  const [btDisplay1, setbtDisplay1] = useState(""); // 送信
  const [btDisplay2, setbtDisplay2] = useState(" hidden"); // 送信中
  const [btDisplay3, setbtDisplay3] = useState(" hidden"); // 注意書き（未入力）
  const handleClick = () => {
    // すべてOKの時だけ送信中に切り替え
    if( focusMailCheck() === "ok" && focusPassCheck() === "ok"){
      setbtDisplay1(" hidden");
      setbtDisplay2("");
      setbtDisplay3(" hidden");
    } else {
      setbtDisplay3(" hidden");
    }
  };

  // mailフォーカス処理
  const emailpattern =  /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
  const [mailaddressError, setMailaddressError] = useState('')
  let mailCheck;
  const focusMailCheck = () => {
    let value = document.querySelector(".mail > input").value
    let ok = document.querySelector(".mailLabel > div > .cOk")
    let ng = document.querySelector(".mailLabel > div > .cNg")
    if(emailpattern.test(value) && value !== ""){
      ok.classList.remove("hidden")
      ng.classList.add("hidden")
      setMailaddressError('')
      mailCheck = "ok"
    }else{
      ok.classList.add("hidden")
      ng.classList.remove("hidden")
      setMailaddressError("正しい形式のメールアドレスを入力してください。")
      mailCheck = "ng"
    }
    return mailCheck
  }

  // passwordフォーカス処理
  const passpattern =  /^[0-9a-zA-Z]*$/;
  const [passError, setPassError] = useState('')
  let passCheck;
  const focusPassCheck = () => {
    let value = document.querySelector(".pass > input").value
    let ok = document.querySelector(".passLabel > div > .cOk")
    let ng = document.querySelector(".passLabel > div > .cNg")
    if(passpattern.test(value) && value !== ""){
      ok.classList.remove("hidden")
      ng.classList.add("hidden")
      setPassError('')
      passCheck = "ok"
    }else{
      ok.classList.add("hidden")
      ng.classList.remove("hidden")
      setPassError("正しい形式のパスワードを入力してください。")
      passCheck = "ng"
    }
    return passCheck
  }

  const { register, handleSubmit } = useForm();

  const onSubmit = useCallback(data => {

    focusMailCheck()
    focusPassCheck()

    // contactform7 form data
    const bodyFormData = new FormData();
    bodyFormData.set(category_4, data[category_4]);
    bodyFormData.set(category_5, data[category_5]);
    bodyFormData.set(category_6, '1234');

    
    console.log(bodyFormData.get(category_4))
    console.log(bodyFormData)
    
    // submit進行
    if ( mailCheck === "ok" && passCheck === "ok") {
      axios({
        method: 'post',
        url:`https://staging.just-trade.jp/api/user/create/`,
        data: {
          email: 'r.igarashi@tomo-partners.jp',
          password: '123456'
        },
        headers: {
          'Content-Type': 'application/json',
          'Token': 'pru9rKlg9gNGgvk21g6ZQyr97f98LMsG',
        },
      }).then(response =>{ //※成功時の遷移先URLを設定 
        if(response.data.errorCode === 400){ // エラーコード400を検知した時
          // 既出のエラー文リセット処理
          let paragraphs = document.querySelectorAll('#submit-reset p');
          paragraphs.forEach(p => {
            p.classList.remove('hidden');
            p.classList.add('hidden');
          });
          if(response.data.errors !== undefined){  // エラー内容があるとき  
            let setArray = Object.keys(response.data.errors)
            let emailResult = setArray.indexOf( 'email' ); // メール重複判別
            let passResult = setArray.indexOf( 'password' ); // パスワード重複判別
            if ( emailResult !== -1 && passResult !== -1 ) { // メール＆パスワード重複
              showErrorText("submit-emailAndPassFalse")
            } else if ( emailResult !== -1 ){ // メール重複
              showErrorText("submit-emailFalse")
            } else if ( passResult !== -1 ){ // パスワード重複
              showErrorText("submit-passFalse")    
            } else { // それ以外のエラー
              showErrorText("submit-false")    
            }
          } else { // それ以外のエラー
            showErrorText("submit-false")
          }
        }else{ // 処理成功
          let checkSubmit = document.getElementById("submit-false");
          checkSubmit.classList.add("hidden");
          window.location.href = '/thanks/'
        }
      }).catch(error => {
        console.log('失敗');
        console.log(error)
        // 既出のエラー文リセット処理
        let paragraphs = document.querySelectorAll('#submit-reset p');
        paragraphs.forEach(p => {
          p.classList.remove('hidden');
          p.classList.add('hidden');
        });
        showErrorText("submit-false")
      });
    } else if( mailCheck === "ng" ) {
      document.querySelector("email").focus();
    } else if( passCheck === "ng" ) {
      document.querySelector("password").focus();
    } else {
    }
  },);

  const downArrow = <svg className="w-6 h-6 relative top-[4.1rem] right-10 text-black" fill="none" stroke ="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
  const checkOk = <img className="cOk hidden w-[22px]" alt="" src={formOk} />
  const checkNg = <img className="cNg hidden w-[22px]" alt="" src={formNg} />

  // 関数（Submitのエラー文を表示＆送信中を戻す処理）
  function showErrorText (id) {
    let checkSubmit = document.getElementById(id);
    checkSubmit.classList.remove("hidden");
    // エラーをキャッチしたら送信中から登録ボタンに戻す処理
    setbtDisplay1("");
    setbtDisplay2(" hidden");
  }

return(
  <>
    <form enctype="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>

      {/* メールアドレス */}
      <div className="mail relative">
        <div className="mailLabel flex relative">
          <label
          htmlFor={category_4}
          className="block text-[#0A61E7] text-xs mb-2">
            <h2 className="text-[#0055B2] font-semibold py-2 mb-2">{category_4_title}&nbsp;{required}</h2>
          </label>
          <div className="relative top-[4px] left-[10px]">{checkOk}{checkNg}</div>
        </div>
        <input
        id={category_4} {...register(category_4, {required: true})}
        placeholder={category_4_pholder}
        className="w-full p-5 mb-1 border-[1px] border-gray-300 focus:outline-[#0A61E7]"
        maxLength="50"
        onBlur={focusMailCheck}
        />
        <div className="mb-3 h-5">
          {mailaddressError && <p className="text-red text-left text-xs">{mailaddressError}</p>}
        </div>
      </div>

      {/* パスワード */}
      <div className="pass relative">
        <div className="passLabel flex relative">
          <label
          htmlFor={category_5}
          className="block text-[#0A61E7] text-xs mb-2">
            <h2 className="text-[#0055B2] font-semibold py-2 mb-2">{category_5_title}&nbsp;{required}</h2>
          </label>
          <div className="relative top-[4px] left-[10px]">{checkOk}{checkNg}</div>
        </div>
        <input
        id={category_5} {...register(category_5, {required: true})}
        placeholder={category_5_pholder}
        className="w-full text-[14px] p-5 mb-1 border-[1px] border-gray-300 focus:outline-[#0A61E7]"
        maxLength="50"
        onBlur={focusPassCheck}
        />
        <div className="mb-3 h-5">
          {passError && <p className="text-red text-left text-xs">{passError}</p>}
        </div>
      </div>

      {/* 送信ボタン */}
      <div className="w-full text-center mb-10">
        <p className={"text-[red]" + btDisplay3}>※未入力・入力エラー箇所を確認してください</p>
        <button onClick={handleClick} className="rounded block my-2 px-14 py-3 text-white text-[13px] font-bold bg-[#333] hover:bg-[#333]/80" type="submit">
          <span className={"" + btDisplay1 }  >{submit}</span>
          <div className={"relative flex item-center justify-center" + btDisplay2}>
            <span id="circle" className="absolute left-[calc(50%_-_70px)] top-[3px]"></span>
            <span className="text-center">送信中</span>
          </div>  
        </button>
        <div id="submit-reset">
          <p id="submit-phoneAndEmailFalse" className="hidden text-left text-mRed">{/* メール重複 */}
            <span>このメールアドレスは既に登録されております。</span><br/><span>このパスワードは既に使用されております。</span>
          </p>
          <p id="submit-emailFalse" className="hidden text-left text-mRed">このメールアドレスは既に登録されております。</p>{/* メール重複 */}
          <p id="submit-emailFalse" className="hidden text-left text-mRed">このパスワードは既に使用されております。</p>{/* pass重複 */}
          <p id="submit-false" className="hidden text-left text-mRed">データの送信に失敗しました。詳細はサポートセンターにお問い合わせください。</p>{/* 通常エラー文 */}
        </div>
      </div>

    </form>
  </>
  );
}