import React from "react"
import Layout from "@components/layout"
import FormsParts from "@components/forms-contactform7-regi";
import { GatsbyImage } from "gatsby-plugin-image"

// Import SVG
import icon_t_SVG1 from '@images/top_t_icon1.svg';
import icon_post_SVG3 from '@images/post_icon3.svg';

// WP CSS
import "@css/@wordpress/block-library/build-style/style.css"
import "@css/@wordpress/block-library/build-style/theme.css"



// コンポーネント呼び出し
import { CampaignGraphqlArray } from "@components/graphql-data-campaign.js"; // 記事情報取得・形成
import { SearchCategList } from "@components/search-categList.js"; // カテゴリ検索機能
import { RelatedContents } from "@components/related-article.js"; // 関連記事取得・形成
import { FixedFoot } from "@components/footer";
import { WordSearch } from "@components/word-search.js";
import { ProfileContents } from "@components/profile.js";
import { SeoContents } from "@components/seo-data.js" // Seo情報設定


import reg_iimg1 from '@images/regi_img01.webp';
import reg_iimg2 from '@images/regi_img02.webp';
import reg_iimg3 from '@images/regi_img03.webp';
import reg_yajirushi from '@images/2step_yajirushi.svg';



const PropRegister = () => {
  const indexUrl = '/';
  const pageTitle = 'お問い合わせ'
  let fixedItem = FixedFoot( 'nomal' ); // 固定フッター
  
  // graphql-data-post.jsから記事データ呼び出し
  const allPostData = CampaignGraphqlArray('', 'allmix');
  var setArray;
  setArray = allPostData.allData[0];

  // Seo情報設定
  const pathName = 'contact';
  const seoTag = SeoContents({ Path:pathName });


  return (
    <> 
      <Layout>
        {seoTag}
        <div className="bg-[#F6F6F6] md:pb-[45px] pb-2">
          <div className="px-5 max-w-xl mx-auto md:max-w-5xl ">
            <div className="pb-4 pt-0 md:pt-10  pt-0  max-w-[800px] ">
              <h2 className="md:text-5xl text-xl font-black mb-5 text-left pt-5 !leading-[1.2]">アカウトを無料登録して<br></br>マイページを開設</h2>
              <h3 className="md:text-xl text-xl font-black  text-left  text-[#1F55A6]">Register an account for free</h3>
            </div>
          </div>
        </div>

        <div className="px-5 max-w-xl mx-auto md:max-w-5xl md:flex md:justify-between  mt-[70px] ">
          
          <div className="max-w-xl">
            <h4 className="mb-3 text-[#1F55A6] font-semibold text-sm tracking-wider">Flow</h4>
            <h5 className="mb-10 text-2xl">当ページよりアカウトを無料登録<br></br>マイページからチャレンジに申請</h5>
            <div className="flex md:mb-[30px] mb-[70px] items-center">
              <div>
                <p><img src={reg_iimg1} alt=""></img>
                </p>
                <p className="text-xs mt-2 min-h-[70px] text-center">アカウントを無料登録し<br></br>
                   マイページにログイン
                </p>
              </div>
              <p className="md:p-5 p-2"><img src={reg_yajirushi} alt=""></img>
              </p>
              <div>
                <p><img src={reg_iimg2} alt=""></img>
                </p>
                <p className="text-xs mt-2 min-h-[70px] text-center">マイページでチャレンジコースを選択後<br></br>
                   個人情報を登録
                </p>
              </div>
              <p className="md:p-5 p-2"><img src={reg_yajirushi} alt=""></img>
              </p>
              <div>
                <p><img src={reg_iimg3} alt=""></img>
                </p>
                <p className="text-xs mt-2 min-h-[70px] text-center">チャレンジ開始！</p>
              </div>
            </div>
            <div class="reg_baner">
            <h4 className="text-[#1F55A6] font-semibold text-sm tracking-wider mb-5">Ongoing campaign</h4>
            <a href={indexUrl + 'campaign/' + setArray.Slug + "/"} className="">
              <p className="min-h-[300px] w-full bg-[#ccc] mb-[70px]">
                <GatsbyImage className="rounded" image={setArray.Image} alt={setArray.AltText} as={`figure`} />
              </p>
            </a>
            </div>
            {/* <div>
              <p className="text-white md:mt-12 mt-5 font-bold mb-8 tracking-widest md:text-current">{postLang["rgst-h2-2"]}</p>
              <div className="md:border-gray-500 ">
                <a href={campaignUri} className={finTagClass}>
                  <div className="w-full rounded">
                    <GatsbyImage className="rounded" image={campaignImage} alt={campaignAltText} as={`figure`} />
                  </div>
                </a>
              </div>
            </div> */}

          </div>

          <div className="max-w-xs  pb-5 mb-10 md:pb-0 md:mb-0 w-full">
            <h4 className="mb-3 text-[#1F55A6] font-semibold text-sm tracking-wider">Form</h4>
            <h5 className="mb-10  text-2xl">最短1分! 簡単マイページ開設</h5>
            <div className="pb-[20px]">
              <FormsParts/>
            </div>
          </div>
          <div class="reg_baner_sp ">
            <h4 className="text-[#1F55A6] font-semibold text-sm tracking-wider mb-5">Ongoing campaign</h4>
            <a href={indexUrl + 'campaign/' + setArray.Slug + "/"} className="">
              <p className="min-h-[300px] w-full bg-[#ccc] mb-[70px]">
                <GatsbyImage className="rounded" image={setArray.Image} alt={setArray.AltText} as={`figure`} />
              </p>
            </a>
            </div>
        </div>
        
        {/* 固定フッター */}
        {fixedItem} 
      </Layout> 
    </>
  )
}
export default PropRegister
